import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Clients from './components/Clients';
import Candidates from './components/Candidates';
import WhyChooseAppsytec from './components/WhyChooseAppsytec';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/candidates" element={<Candidates />} />
        <Route path="/why-choose-appsytec" element={<WhyChooseAppsytec />} />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;
