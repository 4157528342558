import React from 'react';

function WhyChooseAppsytec() {
  return (
    <div className="why-choose-section">
      <h2>Why You Should Choose Appsytec</h2>

      <div className="why-choose-item">
        <h3>No Fees Passed on to You</h3>
        <p>
          We cover all fees, so your agreed rate is 100% what you will get each pay. You can trust that there are no hidden costs or surprises when it comes to your remuneration. Transparency is at the core of what we offer.
        </p>
      </div>

      <div className="why-choose-item">
        <h3>Pay on Time, Every Time</h3>
        <p>
          We partner with one of Australia's top payroll management companies to ensure your payroll is processed efficiently and reliably. Whether it's weekly, fortnightly, or monthly, you can count on us to have your payments processed on schedule. Our partnership with the best ensures that you never have to worry about late payments—pay on time, every time.
        </p>
      </div>

      <div className="why-choose-item">
        <h3>Employee Assistance Program</h3>
        <p>
          We care about your well-being. With our Employee Assistance Program, you can access confidential counselling and support services whenever you need it. This benefit is designed to help you deal with both personal and work-related challenges, making sure that you can maintain a healthy work-life balance and improve your overall well-being.
        </p>
      </div>

      <div className="why-choose-item">
        <h3>Giving Back</h3>
        <p>
          We work with a reputable Australian Environmental Organisation to offset the estimated carbon footprint of each employee. Our commitment to sustainability means we are helping to preserve the environment on your behalf. Additionally, each year we offer you the opportunity to make a difference by donating to a charity of your choice, with the donation calculated based on each hour you work. It's our way of supporting both you and the broader community.
        </p>
      </div>
    </div>
  );
}

export default WhyChooseAppsytec;
